//React
import React from "react";
//PropTypes
import PropTypes from "prop-types";
//Style
import styled from "styled-components";
import theme from "theme";
//Components
import Button from "../Button/Button";

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(props) => props.width || "100"}%;
  height: ${(props) => props.height || "100"}%;
`;

const FormContent = styled.div`
  margin: 4px 0px;
  display: flex;
  flex-direction: column;
`;

const Form = ({ children, action, width, height, text }) => {

  const submit = (e) => {
    e.preventDefault();
    action();
  }

  return (
    <FormStyled onSubmit={(e) => submit(e)} width={width} height={height}>
      <FormContent>{children}</FormContent>
      <Button
        type="submit"
        backgroundColor={theme.green}
        textColor={theme.white}
      >
        <span>{text}</span>
      </Button>
    </FormStyled>
  );
};
Form.propTypes = {
  action: PropTypes.func.isRequired,
  children: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default Form;

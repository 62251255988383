// React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/Spinner/Spinner";
import Icon from "utils/icon";
import {
  getUserFromKey,
  acceptList,
  autoAcceptList,
} from "../../../actions/userList";
import styled from "styled-components";
import Form from "../../../components/Form/Form";
import Input from "../../../components/Input/Input";
import A2Logo from "components/A2Logo";
//i18n
import { FormattedMessage } from "react-intl";
import messages from "../../../containers/messages";
import { navigate } from "gatsby";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

const Title = styled.div`
  display: flex;
  font-size: 1.2em;
  margin-top: 1em;
  margin: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  margin-top: 25px;
  width: 20%;
  min-width: 300px;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
    min-width: 200px;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 1em;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
`;

const CheckUserList = ({ pubKey }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const { userListToJoin, userToJoin, isAccept, isAutoAccept,linkValidationDate, alreadyAccept } =
    useSelector((state) => state.userList);
  const dispatch = useDispatch();
  const get = () => dispatch(getUserFromKey(pubKey));
  const accept = (mail, pwd, username, phone, organization) =>
    dispatch(acceptList(mail, pwd, username, phone, organization, pubKey));
  const autoAccept = () => dispatch(autoAcceptList(pubKey));
  let redirectTimeout = null;

  useEffect(() => {
    if (pubKey) {
      get();
    }
  }, []);

  useEffect(() => {
    if (userToJoin.isValidate && !isAutoAccept) {
      autoAccept();
    }
  }, [userToJoin]);

  useEffect(() => {
    if ((userToJoin.isValidate || isAccept) && !redirectTimeout) {
      // test set according to the following code
      redirectTimeout = setTimeout(() => navigate("/l"), 8000);
      return () => {
        clearTimeout(redirectTimeout);
      };
    }
  }, [userToJoin, isAccept]);

  const checkUserData = (e) => {
    if (password === "" || confirmPassword === "") {
      setError(<FormattedMessage {...messages.user_error_field_1} />);
    } else if (password !== confirmPassword) {
      setError(<FormattedMessage {...messages.user_error_field_2} />);
    } else {
      accept(
        userToJoin.mail,
        password,
        userToJoin.username,
        userToJoin.phone,
        userToJoin.organization
      );
    }
  };

  const dateDiff = (date) => {
    // check if now and date1 are less of 2 hours apart
    const date1 = new Date(date);
    const date2 = new Date();
    const diff = Math.abs(date2 - date1);
    const diffHours = Math.ceil(diff / (1000 * 60 * 60));
    return diffHours;
  };

  return (
    <Container>
      {Object.keys(userListToJoin).length ? (
        alreadyAccept ? (
          <>
            <Header>
              <A2Logo small />
            </Header>
            <Title>
              <FormattedMessage {...messages.user_already_join} />
            </Title>
          </>
        ) : // 168 hours = 7 days
        linkValidationDate && dateDiff(linkValidationDate) <= 168 ? (
          userToJoin?.isValidate || isAccept ? (
            <>
              <Header>
                <A2Logo small />
              </Header>
              <Title>
                <FormattedMessage {...messages.user_join} />
              </Title>
            </>
          ) : (
            <>
              <Title>
                <FormattedMessage {...messages.user_complete} />
              </Title>
              <FormContainer>
                <Form text={"Envoyer"} action={() => checkUserData()}>
                  <FormattedMessage {...messages.user_mail}>
                    {(msg) => (
                      <Input
                        type="text"
                        title={msg}
                        value={userToJoin.mail}
                        disabled={true}
                      />
                    )}
                  </FormattedMessage>
                  <FormattedMessage {...messages.user_password}>
                    {(msg) => (
                      <Input
                        type="password"
                        title={msg}
                        value={password}
                        action={(e) => setPassword(e.target.value)}
                      />
                    )}
                  </FormattedMessage>
                  <FormattedMessage {...messages.user_confirm_password}>
                    {(msg) => (
                      <Input
                        type="password"
                        title={msg}
                        value={confirmPassword}
                        action={(e) => setConfirmPassword(e.target.value)}
                      />
                    )}
                  </FormattedMessage>
                  {error && <Error>{error}</Error>}
                </Form>
              </FormContainer>
            </>
          )
        ) : (
          <Title>
            <FormattedMessage {...messages.user_link_not_valid} />
          </Title>
        )
      ) : (
        <Spinner>
          <Icon icon="LoadingHalf" />
        </Spinner>
      )}
    </Container>
  );
};

export default CheckUserList;

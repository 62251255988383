//React
import React from "react";
//PropTypes
import PropTypes from "prop-types";
//Style
import styled from "styled-components";
import theme from "theme";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const InputStyled = styled.input`
  height: 32px;
  border: 1px solid ${theme.ui.grey};
  padding: 0 10px;
  border-radius: 5px;
  margin-bottom: 16px;
`;

const Span = styled.span`
  padding-bottom: 8px;
`;

const Input = ({ placeholder, textColor, title, type, action, value, disabled }) => {
  return (
    <Container>
      {title && <Span>{title}</Span>}
      <InputStyled
        placeholder={placeholder}
        text={textColor}
        type={type}
        onChange={action}
        value={value}
        disabled={disabled}
      />
    </Container>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  action: PropTypes.any,
  value: PropTypes.any,
};

export default Input;
